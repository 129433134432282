import React, { useEffect, useState, useRef, Component } from 'react';
import moment from 'moment';
import {
    format, startOfMonth, endOfMonth, differenceInDays, addDays, getMonth, getYear,
    endOfToday, differenceInCalendarDays, subDays, isMonday, isTuesday, isWednesday, isThursday,
    isFriday, isSaturday, isSunday, isBefore, startOfToday, isAfter, isSameDay,
    addMonths,
    parse
} from 'date-fns';
import commonFunction from '../../../../lib/utils/common';
import cf from '../../../../lib/utils/common';
import ModalPopupComponent from '../../../ModalPopupComponent/theme5/ModalPopupComponent'

// import styles from "./calendar.module.css";

const MonthList = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

const weekList = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

export default class MobileCalender extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dateFormat: !commonFunction.isValueEmpty(this.props.dateFormat) ? this.props.dateFormat : "dd/MM/yyyy",
            isCalendarVisible: false,
            numberOfClicks: 0,
            twoCalendarsArray: [],
            isDoubleSelection: !commonFunction.isValueEmpty(this.props.doubleSelection) ? this.props.doubleSelection : false,
            dateLimitArr: this.props.startDateLimit,
            currentMonths: [getMonth(startOfMonth(new Date()))],
            selectedMonthStartDate: startOfMonth(startOfToday()),
            selectedMonthEndDate: endOfMonth(startOfMonth(new Date())),
            // startDate: this.props.startDate,
            endDate: this.props.endDate,
            hoverEndDate: this.props.endDate,
            totalDays: "",
            pageX: "",
            pageY: "",
            initPageX: "",
            initPageY: "",
            totalMonths: !commonFunction.isValueEmpty(this.props.totalMonths) ? this.props.totalMonths : 12,
            serviceType: this.props.serviceType ? this.props.serviceType : ""
        };
    }
    componentDidMount() {
        this.setState({
            currentMonths: this.props.type === "double" ? !commonFunction.isValueEmpty(this.state.dateLimitArr) ?
                [getMonth(startOfMonth(new Date(this.specDateEle(this.state.dateLimitArr, 'y'), this.specDateEle(this.state.dateLimitArr, 'm'), this.specDateEle(this.state.dateLimitArr, 'd')))),
                getMonth(startOfMonth(new Date(this.specDateEle(this.state.dateLimitArr, 'y'), this.specDateEle(this.state.dateLimitArr, 'm'), this.specDateEle(this.state.dateLimitArr, 'd')))) + 1] :
                [getMonth(startOfMonth(new Date())), getMonth(startOfMonth(new Date())) + 1] : !commonFunction.isValueEmpty(this.state.dateLimitArr) ?
                [getMonth(startOfMonth(new Date(this.specDateEle(this.state.dateLimitArr, 'y'), this.specDateEle(this.state.dateLimitArr, 'm'), this.specDateEle(this.state.dateLimitArr, 'd'))))] :
                [getMonth(startOfMonth(new Date()))],
            selectedMonthStartDate: !commonFunction.isValueEmpty(this.state.dateLimitArr) ? startOfMonth(new Date(this.specDateEle(this.state.dateLimitArr, 'y'), this.specDateEle(this.state.dateLimitArr, 'm'), this.specDateEle(this.state.dateLimitArr, 'd'))) : startOfMonth(startOfToday())
        })
    }
    componentDidUpdate() {
        if (this.props.endDate !== this.state.endDate) {
            this.setState({ endDate: this.props.endDate });
        }
    }
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.startDateLimit) {
            this.setState({
                dateLimitArr: nextProps.startDateLimit,
                startDate: nextProps.startDate,

            }, () => {
                this.setState({
                    currentMonths: this.props.type === "double" ?
                        !commonFunction.isValueEmpty(this.state.dateLimitArr) ?
                            [getMonth(startOfMonth(new Date(this.specDateEle(this.state.dateLimitArr, 'y'), this.specDateEle(this.state.dateLimitArr, 'm'), this.specDateEle(this.state.dateLimitArr, 'd')))), getMonth(startOfMonth(new Date(this.specDateEle(this.state.dateLimitArr, 'y'), this.specDateEle(this.state.dateLimitArr, 'm'), this.specDateEle(this.state.dateLimitArr, 'd')))) + 1] :
                            [getMonth(startOfMonth(new Date())), getMonth(startOfMonth(new Date())) + 1] : !commonFunction.isValueEmpty(this.state.dateLimitArr) ?
                            [getMonth(startOfMonth(new Date(this.specDateEle(this.state.dateLimitArr, 'y'), this.specDateEle(this.state.dateLimitArr, 'm'), this.specDateEle(this.state.dateLimitArr, 'd'))))] :
                            [getMonth(startOfMonth(new Date()))],
                    selectedMonthStartDate: startOfMonth(startOfToday())
                })
            })
        }
    }

    handleClickOutside = () => {
        if (this.state.isCalendarVisible === true) this.setState({ isCalendarVisible: false });
    };
    specDateEle(date, type) {
        try {
            let toDefaultFormat = moment(date, this.state.dateFormat);
            let getDate = toDefaultFormat.format(this.state.dateFormat);
            let getYear = typeof date === 'string' ? new Date(getDate).getFullYear() : date.getFullYear();
            let getMonth = typeof date === 'string' ? new Date(getDate).getMonth() : date.getMonth();
            let getDay = typeof date === 'string' ? new Date(getDate).getDate() : date.getDate();
            switch (type) {
                case 'y':
                    return getYear
                case 'm':
                    return getMonth
                case 'd':
                    return getDay
                case 'a':
                    return [getYear, getMonth, getDay];
                default:
            }
        } catch (err) {
            console.log('catch yyyyy', err)
        }
    }
    onClickChangeVisibility = () => {
        document.querySelector("body").style.overflow =   !this.props.isVisible     && this.props?.isMobile ? "hidden" : "auto" ;
        this.props.changeVisibility(!this.props.isVisible)
        
    }
    onClickDateCell = (day, month, year, bool) => {
        document.querySelector("body").style.overflow =   !this.props.isVisible && this.props?.isMobile  ? "hidden" : "auto" ;
     
        if (!bool) { //new added
            if (this.state.isDoubleSelection === true) {
                let date = new Date(year, month, day);
                if (this.state.numberOfClicks === 0) {
                    this.setState({ numberOfClicks: 1, totalDays: '' }, () => {
                        this.props.onChangeDate(date, 'startDate');
                        this.props.onChangeDate('', 'endDate');
                    });
                }
                if (this.state.numberOfClicks === 1) {
                    let differenceInDays = differenceInCalendarDays(new Date(date), new Date(this.props.startDate))
                    this.setState({ numberOfClicks: 0 }, () => {
                        if (differenceInDays < 0) {
                            this.props.onChangeDate(date, 'startDate');
                            this.props.onChangeDate('', 'endDate');
                        } else {
                            this.setState({ numberOfClicks: 0 }, () => {
                                this.props.onChangeDate(date, 'endDate');
                                // this.props.changeVisibility(!this.props.isVisible)
                            });
                        }
                    });
                }
            } else {
                if (bool === false) {
                    let date = moment(new Date(year, month, day)).format(this.state.dateFormat);
                    this.props.onChangeDate(date);
                }
            }
        }
    }
    renderCalendar() {
        let temp = this.state.selectedMonthStartDate;
        let twoCalendarsArray = [];
        for (let i = 0; i < this.state.totalMonths; i++) {
            let StartDateOfMonth = temp;
            let monthHead = addDays(StartDateOfMonth, i === 0 ? 0 : 1);
            let endDateOfMonth = endOfMonth(monthHead);
            let dayDifference = differenceInCalendarDays(endDateOfMonth, monthHead);
            let days = [];
            let month = getMonth(monthHead)
            let year = getYear(monthHead)
            let startOfMonthDay = isMonday(monthHead) ? 0 : isTuesday(monthHead) ? 1 : isWednesday(monthHead) ? 2 : isThursday(monthHead) ? 3 : isFriday(monthHead) ? 4 : isSaturday(monthHead) ? 5 : 6;
            let startDateLimit = this.props.startDateLimit;
            let isHoliday = this.props.isHoliday ? this.props.isHoliday : false;
            let holidayStartDate = this.props.holidayStartDate ? this.props.holidayStartDate : '';
            let holidayEndDate = this.props.holidayEndDate ? this.props.holidayEndDate : '';
            let startDate = this.props.startDate;
            let endDate = this.state.endDate;
            let hoverDate = this.state.hoverEndDate;
            for (let k = 0; k < startOfMonthDay; k++) {
                days = [...days, { day: "", month: month, year: year, isDisable: true, selectedDate: false, highlight: false }];
            }
            for (let j = 0; j <= dayDifference; j++) {
                let isBeforeDate = isBefore(new Date(year, month, j + 1), new Date(this.specDateEle(startDateLimit, 'y'), this.specDateEle(startDateLimit, 'm'), this.specDateEle(startDateLimit, 'd')));
                let disableDate = false;

                if (isHoliday) {
                    let isHLDBeforeDate = isBefore(new Date(year, month, j + 1), new Date(this.specDateEle(holidayStartDate, 'y'), this.specDateEle(holidayStartDate, 'm'), this.specDateEle(holidayStartDate, 'd')));
                    let isHLDAfterDate = isAfter(new Date(year, month, j + 1), new Date(this.specDateEle(holidayEndDate, 'y'), this.specDateEle(holidayEndDate, 'm'), this.specDateEle(holidayEndDate, 'd')));
                    disableDate = isHLDBeforeDate == true || isHLDAfterDate == true ? true : false;
                }
                if (isBeforeDate === true || disableDate == true) {
                    days = [...days, { day: j + 1, month: month, year: year, isDisable: true, selectedDate: false, highlight: false }];
                } else {
                    days = [...days, { day: j + 1, month: month, year: year, isDisable: false, selectedDate: false, highlight: false }];
                }

                //     console.log("day aarti", days , j+1)
                if (this.state.isDoubleSelection === true) {
                    let isBeforeDate = isBefore(new Date(year, month, j + 1), new Date(this.specDateEle(endDate, 'y'), this.specDateEle(endDate, 'm'), this.specDateEle(endDate, 'd')));
                    let isAfterDate = isAfter(new Date(year, month, j + 1), new Date(this.specDateEle(startDate, 'y'), this.specDateEle(startDate, 'm'), this.specDateEle(startDate, 'd')));
                    // console.log('UUUUUU==== ' + new Date(year, month, j + 1) + ' ==== ' + endDate + ' ==== ' + startDate)
                    //    console.log("isAfterDate",isAfterDate);
                    //    console.log("isBeforeDate",isBeforeDate);
                    if (isAfterDate && isBeforeDate) {
                        //    days[j + 1].highlight = true;
                        days[j].highlight = true;
                    }
                }
            }
            if (this.state.isDoubleSelection === true) {
                let startDateIndex = days.findIndex((items) => new Date(items.year, items.month, items.day).toString() === new Date(this.specDateEle(startDate, 'y'), this.specDateEle(startDate, 'm'), this.specDateEle(startDate, 'd')).toString());
                let endDateIndex = days.findIndex((items) => new Date(items.year, items.month, items.day).toString() === new Date(this.specDateEle(endDate, 'y'), this.specDateEle(endDate, 'm'), this.specDateEle(endDate, 'd')).toString());
                if (startDateIndex !== -1) {
                    days[startDateIndex].selectedDate = true;
                }
                if (endDateIndex !== -1) {
                    days[endDateIndex].selectedDate = true;
                }
            } else {
                let SelectedDate = this.props.value;
                let selectedDateIndex = days.findIndex((items) => new Date(items.year, items.month, items.day).toString() === new Date(this.specDateEle(SelectedDate, 'y'), this.specDateEle(SelectedDate, 'm'), this.specDateEle(SelectedDate, 'd')).toString())
                if (selectedDateIndex !== -1) {
                    days[selectedDateIndex].selectedDate = true;
                }
            }
            temp = endDateOfMonth;
            let calendarId = `calendar-${year}-${month}`;
        
            twoCalendarsArray.push(
                <div className={this.props.styles.calendar_whole_body} id={calendarId} key={i}>
                    <div className={this.props.styles.calendar_head}>
                        <div className={this.props.styles.calendar_head_center_side}>
                            <h3>{format(monthHead, 'MMMM yyyy')}</h3>
                        </div>
                    </div>
                    <div className={this.props.styles.calendar_body}>
                        {/* <div className={this.props.styles.calendar_week_heads}>
                            {weekList.map((ele, ix) => {
                                return (
                                    <div className={this.props.styles.coln_center_week} key={i + "th_month_" + ix + "week"}>
                                        {ele}
                                    </div>
                                )
                            })}
                        </div> */}
                        <div className={this.props.styles.calendar_day_list}>
                            {days.map((ele, idx) => {
                                let isHighLight = false;
                                let isHoverDate = false;
                                if (this.state.isDoubleSelection === true) {
                                    let isBeforeDate = isBefore(new Date(ele.year, ele.month, ele.day), new Date(this.specDateEle(hoverDate, 'y'), this.specDateEle(hoverDate, 'm'), this.specDateEle(hoverDate, 'd')));
                                    let isBeforeEndDate = isBefore(new Date(ele.year, ele.month, ele.day), new Date(this.specDateEle(endDate, 'y'), this.specDateEle(endDate, 'm'), this.specDateEle(endDate, 'd')));
                                    let isAfterDate = isAfter(new Date(ele.year, ele.month, ele.day), new Date(this.specDateEle(startDate, 'y'), this.specDateEle(startDate, 'm'), this.specDateEle(startDate, 'd')));
                                    isHoverDate = isSameDay(new Date(ele.year, ele.month, ele.day), new Date(this.specDateEle(hoverDate, 'y'), this.specDateEle(hoverDate, 'm'), this.specDateEle(hoverDate, 'd')));
                                    if ((isAfterDate && isBeforeDate && commonFunction.isValueEmpty(endDate)) || (isAfterDate && isBeforeEndDate && (!commonFunction.isValueEmpty(endDate)))) {
                                        isHighLight = true;
                                    }
                                }
                                return (
                                    <div className={(ele.selectedDate === true && ele.day) ? this.props.styles.day_cell_center + ' ' + this.props.styles.selected_calendar_date : isHighLight === true && ele.day != "" ? this.props.styles.day_cell_center_highlight : this.props.styles.day_cell_center} key={i}
                                        onClick={(e) => { e.stopPropagation(); this.onClickDateCell(ele.day, ele.month, ele.year, ele.isDisable) }} onMouseEnter={(e) => this.onHoverDayCell(ele, e)} >
                                        {this.state.isDoubleSelection === true && !commonFunction.isValueEmpty(ele.day) && ele.isDisable === false && !commonFunction.isValueEmpty(hoverDate) && isHoverDate && !commonFunction.isValueEmpty(this.state.totalDays) && <div class={this.props.styles.container_tooltip}>{this.state.totalDays} Night</div>}
                                        <span className={ele.isDisable === true ? this.props.styles.calendar_day_disable : ''}>{ele.day}</span>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            )
        }
        if (this.props.label === 'Return' && this.state.startDate) {
            const parsedDate = parse(this.state.startDate, 'dd-MMM-yyyy', new Date())
            let month = getMonth(parsedDate)
            let year = getYear(parsedDate)
            let calendarId = `calendar-${year}-${month}`;
            setTimeout(() => {
                const element = document.getElementById(calendarId);
                if (element) {
                    element.scrollIntoView({behavior: 'smooth', block: 'center'});
                }
            }, 0);
        }
     
        return twoCalendarsArray
    }
    onClickDoneBtn = () => {
        this.props.changeVisibility(false)
    }
    onHoverDayCell = (currentDate, e) => {
        let date = moment(new Date(currentDate.year, currentDate.month, currentDate.day)).format(this.props.dateFormat ? this.props.dateFormat : "DD/MMM/yyyy");
        this.setState({
            hoverEndDate: date
        })
        if (!commonFunction.isValueEmpty(this.props.startDate) && commonFunction.isValueEmpty(this.state.endDate)) {
            this.setState({
                hoverEndDate: date, pageX: Number(e.clientX) - Number(this.state.initPageX), pageY: Number(e.clientY) - Number(this.state.initPageY)
            }, () => {
                let difference = differenceInCalendarDays(new Date(currentDate.year, currentDate.month, currentDate.day), new Date(this.props.startDate))
                if (difference > 0) this.setState({ totalDays: difference })
                else this.setState({ totalDays: '' })
            })
        }
    }
    onMouseMoveCalendar = (e, eventUsed) => {
        switch (eventUsed) {
            case 'onMouseEnter':
                this.setState({ initPageX: e.clientX, initPageY: e.clientY })
                break;
            default:
                break;
        }
        e.preventDefault()
    }


    render() {

        const dateInputStyles = {
            border: 'none',
            outline: 'none',
            color: '#005a95',
            padding: '0',
            fontWeight: '600',
            width: '100%'
        }
        // console.log('calendar_wid_container', this.props)
        // console.log('calendar_wid_container11111', this.state)
        return (
            <div className={this.props.styles.calendar_wid_main_container} 
            // onClick={(e) => { e.stopPropagation(); this.onClickChangeVisibility() }}
            > 
            <div className={this.props.styles.calendar_wid_container} >
                <label>{this.props.label}</label>
                <div className={`${this.props.styles.calendar_wid_main} ${this.props.triptype  == "Multicity" ? this.props.styles.multicity_Calwrap : ""}`}>
                    {/* {!commonFunction.isValueEmpty(this.props.iconImage) ? (
                        <img src={this.props.iconImage} className={this.props.styles.flight_search_wid_inputfield_img} alt=""/>
                    ) : null} */}
                    <span>
                        {this.props.iconImage}
                    </span>

                    {this.state.isDoubleSelection === true ? (
                        <input type='text' autoComplete={'off'} id="myTextField" style={dateInputStyles} className={this.props.styles.search_widget_input_new} readOnly
                            placeholder={!commonFunction.isValueEmpty(this.props.placholder) ? this.props.placholder : ""}
                            value={!commonFunction.isValueEmpty(this.props.value) ? this.props.value : ""}
                            onClick={(e) => { e.stopPropagation(); this.onClickChangeVisibility() }}
                        />
                    ) : (
                        <>
                        {   this.props.triptype === "oneWay" && this.props.label == "Return"  ? <div onClick={()=>{
                            // this.onClickChangeVisibility()
                            this.props.selectTripbtn();
                        }}>
                        <p  className={this.props.styles.roundText}>
                          {"Save more!"} 
                          <br/>
                          {"Book a round trip"}
                        </p>
                        </div> :" "}

                      <input type='text' 
                        autoComplete={'off'}
                         id="myTextField" 
                        style={dateInputStyles} 
                        className={ this.props.triptype !== "oneWay" || this.props.label !== "Return" 
                        ? this.props.styles.search_widget_input_new
                        : this.props.styles.roundDateDisable} readOnly
                            placeholder={  this.props.triptype === "oneWay" && this.props.label == "Return"   ? "" : !commonFunction.isValueEmpty(this.props.placholder) ? this.props.placholder : ""}
                            value={  this.props.triptype === "oneWay" && this.props.label == "Return"   ? "" : !commonFunction.isValueEmpty(this.props.value) 
                                ? format(new Date(this.props.value) ,"dd LLL yyyy") 
                                : ""}
                            onClick={(e) => {     e.stopPropagation();
                             if(this.props.triptype  == "oneWay" && this.props.label == "Return" )
                             {  
                                document.querySelector("body").style.overflow =   !this.props.isVisible  && this.props?.isMobile ? "hidden" : "auto" ;
     
                                 this.props.selectTripbtn() }

                           else {  this.onClickChangeVisibility()}

                            }}

                        /> 
                  </> 
                   )}
                    <div className={ this.props.triptype !== "oneWay"  || this.props.label !== "Return" ? this.props.styles.flt_trav_label : this.props.styles.roundDateDisable}>
                      
                        {  this.props.triptype === "oneWay" && this.props.label == "Return"   ? "" : !cf.isValueEmpty(this.props.weekDay) ? this.props.weekDay : ""}
                    </div>
                </div>
                {this.props.isVisible === true ? (
                      <div className={ this.props.styles.modal_container}>
                      <div className={  this.props.styles.modal_content}> 
                    <div className={this.props.styles.calendar_wid_container_popup}>
                        <div className={this.props.styles.date_header_container}>
                        <div className={  this.props.styles.cal_header}>
                        <span onClick={(e) => { e.stopPropagation(); this.onClickChangeVisibility() }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14px" height="18px">
                        <path fill-rule="evenodd" fill="rgb(255, 255, 255)" d="M13.444,3.241 L4.629,11.988 L13.446,20.737 C14.196,21.481 14.196,22.687 13.446,23.431 C12.696,24.175 11.481,24.175 10.731,23.431 L0.566,13.343 C0.283,13.062 0.107,12.715 0.038,12.353 C-0.082,11.750 0.092,11.102 0.563,10.635 L10.729,0.546 C11.478,-0.197 12.694,-0.197 13.444,0.546 C14.193,1.290 14.193,2.497 13.444,3.241 Z"/>
                        </svg>
                        </span>
                        <p className={  this.props.styles.cal_headerLabel}>{this.props?.headerlabel}</p>
                        </div>
                        {/* <img src="/images/download.png" onClick={(e) => { e.stopPropagation(); this.onClickChangeVisibility() }} className={this.props.styles.flight_search_wid_inputfield_img} /> */}
                        {/* <div className={this.props.styles.calendar_modal_close} onClick={(e) => { e.stopPropagation(); this.onClickChangeVisibility() }}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                        </div> */}
                        <div className={this.props.styles.calendar_head_container}>
                            <div className={this.props.styles.calendar_head_main}>

                               
                                <div className={`${this.props.styles.calendar_head_com}  ${this.props.dateType === 'departure' ? this.props.styles.cal_Active : ''} ${this.props.styles.calendar_head_left}  ${ this.props.triptype  == "Multicity" ? this.props.styles.multicityDate : ""}`}>

                                    {/* <span className={this.props.styles.calendar_head_title}>{this.props.isHoliday ? "START DATE" : "DEPARTURE DATE"}</span> */}
                                    <span className={this.props.styles.calendar_head_title}>{this.props.calaHeadTitleDepart}</span>
                                    <h5 className={this.props.styles.calendar_head_date}>{!commonFunction.isValueEmpty(this.props.startDate) ? format(new Date(this.props.startDate), 'dd MMMM yyyy') : 'Select from date'}</h5>
                                </div>

                              { this.props.triptype  !== "Multicity" ?  <div className={`${this.props.styles.calendar_head_com} ${this.props.dateType === 'return' ? this.props.styles.cal_Active : ''} ${this.props.styles.calendar_head_right}`} onClick={(e) => {  this.props.selectTripbtn(); 
                                    e.stopPropagation();
                                //  this.onClickChangeVisibility()
                                      }}>
                                    {this.props.dateType == "return" ?
                                    <span className={this.props.styles.closeb_icon} onClick={(e) => { e.stopPropagation();
                                    //  this.onClickChangeVisibility(); 
                                     this.props.selectTripbtn(); }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="9px" height="9px">
                                        <path fill-rule="evenodd" fill="rgb(0, 0, 0)" d="M1.211,0.827 C0.973,0.850 0.800,1.061 0.823,1.299 C0.833,1.401 0.880,1.498 0.954,1.569 L3.882,4.504 L0.954,7.432 C0.785,7.601 0.785,7.876 0.954,8.046 C1.124,8.215 1.399,8.215 1.568,8.046 L1.568,8.046 L4.503,5.111 L7.431,8.046 C7.601,8.215 7.876,8.215 8.045,8.046 C8.215,7.876 8.215,7.601 8.045,7.432 L8.045,7.432 L5.110,4.504 L8.045,1.569 C8.217,1.403 8.221,1.130 8.056,0.958 C7.970,0.870 7.851,0.822 7.728,0.827 C7.616,0.829 7.510,0.876 7.431,0.955 L4.503,3.883 L1.568,0.955 C1.486,0.873 1.374,0.826 1.258,0.827 C1.242,0.826 1.227,0.826 1.211,0.827 Z"/>
                                        </svg>
                                    </span>
                                        // <img src="/images/download.png" onClick={(e) => { this.props.selectTripbtn(); e.stopPropagation(); this.onClickChangeVisibility() }} className={this.props.styles.flight_search_wid_inputfield_img} />
                                        : null}
                                    {/* <span className={this.props.styles.calendar_head_title}>{this.props.isHoliday ? "END DATE" : "RETURN DATE"}</span> */}
                                    <span className={this.props.styles.calendar_head_title}>{this.props.calaHeadTitleReturn}</span>
                                    <h5 className={this.props.styles.calendar_head_date}>{this.props.dateType == "departure" ? "Save more on round trip" : !commonFunction.isValueEmpty(this.props.endDate) ? format(new Date(this.props.endDate), 'dd MMMM yyyy') : 'Select from date'}</h5>
                                </div> : null}

                            </div>
                           
                            <div className={this.props.styles.calendar_week_heads}>
                            {weekList.map((ele, ix) => {
                                return (
                                    <div className={this.props.styles.coln_center_week} key={"th_month_" + ix + "week"}>
                                        {ele}
                                    </div>
                                )
                            })}
                        </div>
                        </div>
                        </div>

                      

                            <div className={this.props.styles.calendar_main_body_popup}>
                                {this.renderCalendar()}
                            </div>
                            <div className={this.props.styles.calendar_footer}>
                                <button onClick={(e) => { e.stopPropagation(); this.onClickDoneBtn() }}>  Apply Now
                                </button>
                            </div>
                        </div>
                        </div>
                                        
                    </div>
                ) : null}
            </div>
            <span
                                            className={
                                              this.props.styles.cal_arrow
                                            }
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="18px"
                                              height="11px"
                                            >
                                              <path
                                                fill-rule="evenodd"
                                                fill="rgb(38, 106, 172)"
                                                d="M17.373,2.754 L9.979,10.211 C9.840,10.351 9.679,10.453 9.507,10.522 C9.003,10.728 8.403,10.626 7.994,10.213 L0.599,2.756 C0.054,2.205 0.054,1.314 0.599,0.764 C1.145,0.214 2.029,0.214 2.574,0.764 L8.985,7.230 L15.399,0.762 C15.944,0.213 16.828,0.213 17.373,0.762 C17.919,1.313 17.919,2.204 17.373,2.754 Z"
                                              />
                                            </svg>
                                          </span>
            </div>
        )
    }
}


